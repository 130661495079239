<template>
  <div class="category-list">
    <MainHeader />
    <CommonTop />
    <div class="nav-box">
      <div class="page-content-wrap">
        <Nav />
      </div>
    </div>
    <div class="section">
      <div class="breadcrumb page-content-wrap">
        <span>所在位置：</span>
        <div class="breadcrumb-item">
          <router-link class="nav-item" to="/">首页</router-link>
        </div>
        <div class="breadcrumb-item">
          <span class="icon iconfont icon-hangdongjiantou"></span>
          <span class="nav-item">{{ pid != 1 ? navData.name : '特惠专区'}}</span>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="page-content-wrap list-container">
        <div class="head">
          <div class="label">{{pid != 1 ? navData.name : '特惠专区'}}</div>
        </div>
        <div class="list">
          <div @click="jumpGoodsList(item)" v-for="(item, index) in categoryList" :key="index" class="list-item">
            <div class="cat-pic">
              <img v-if="pid != 1" :src="item.icon" alt="">
              <img v-else :src="item.cover" alt="">
            </div>
            <div class="cat-name" >{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
import Nav from '@/components/nav/index.vue'
export default {
  components: {
    MainHeader,
    CommonTop,
    Nav
  },
  data () {
    return {
      pid: '',
      categoryList: [],
      navData: {}
    }
  },
  created () {
    this.pid = this.$route.query.id
    this.getNavData()
    this.getCategoryList()
  },
  methods: {
    // 获取面包屑导航数据
    async getNavData () {
      const res = await this.$http.get('/common/category/nav', { category_id: this.pid })
      // console.log(res)
      this.navData = res
    },
    // 获取二级分类列表
    async getCategoryList () {
      let res = []
      if (this.pid == 1 ) {
        const command = await this.$http.get('api/recommand-goods?type=6')
        res = command[0].goods
      } else
        res = await this.$http.get('/common/category', { pid: this.pid })
      this.categoryList = res
    },
    // 跳转商品列表页
    jumpGoodsList (item) {
      if (this.pid == 1)
        this.$router.push({ path: '/goods/detail', query: { id: item.id } })
      else
        this.$router.push({ path: '/goods/list', query: { id: item.id } })

    }
  }
}
</script>
<style lang="scss" scoped>
.category-list {
  .section {
    background-color: #f5f5f5;
  }
  .breadcrumb {
    padding-top: 20px;
    display: flex;
    font-size: 14px;
    color: #999999FF;
    .breadcrumb-item {
      margin-right: 10px;
      a {
        color: #999999;
        text-decoration: none;
      }
      .iconfont {
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
  .list-container {
    padding: 30px 0;
    min-height: 450px;
    .head {
      .label {
        font-size: 16px;
        font-weight: bold;
        padding-left: 10px;
        border-left: 3px solid #D9262C;
      }
    }
    .list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .list-item {
        width: 192px;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 16px;
        padding-bottom: 20px;
        cursor: pointer;
        margin-right: 9px;
        margin-top: 9px;
        border-radius: 3px;
        &:nth-child(-n + 6) {
          margin-top: 0;
        }
        &:nth-child(6n) {
          margin-right: 0;
        }
        transition: all .2s linear;
        &:hover {
          box-shadow: 0 15px 30px rgba(0,0,0,.1);
          transform: translate3d(0,-2px,0);
          .cat-pic {
            img {
              // transform: scale(1.1);
            }
          }
        }
        .cat-pic {
          width: 160px;
          height: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            max-height: 100%;
            transition: all .3s;
          }
        }
        .cat-name {
          text-align: center;
          margin-top: 20px;
          color: #333333;
        }
      }
    }
  }
}
</style>
